.footer-logo{
    width: 13%;
    height: auto;
}
.footer-link-div{
    border-right: solid 1px var(--primary-color);
    padding: 1px 10px;
    font-size: 12px;
    color: var(--primary-color);
    font-weight: 500;
    cursor: pointer;
    text-decoration: none !important;

}
/* .footer-link-div:hover{ */
    /* color: var(--secondary-color); */
/* } */
.footer-bg{
    background-color: var(--secondary-color);
    position: relative;
    bottom: 0;
}
.footer-div{
    /* padding: 50px 0px 0px 0px; */
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;

}
.right-border{
    border-right: none !important;
}

.cool-link {
    display: inline-block;
    /* color: #000; */
    text-decoration: none;
}

.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    border-radius: 30px;
    background:  #1B6166 !important;
    transition: width .3s;
}

.cool-link:hover::after {
    width: 100%;
   
}

@media (max-width :480px){
    .footer-link-div{
        font-size: 11px;
    }
    .footer-div{
        height: 15vh;
    }
    .footer-logo{
        width: 62%;
       height: 7vh;
    }
}