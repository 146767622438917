.content-div{
    background-color:var(--secondary-color);
    height: 40vh;
    display: flex;
    border-radius: 0 0 100% 100%;
    align-items: baseline;
    overflow: hidden;
    box-shadow:6px 26px 16px lightgrey;
    z-index: 3;
    position: relative;
    justify-content: center;
}
.wwa-heading{
    color:var(--primary-color);
    font-size: 45px;
    font-weight: 600;
    text-shadow: 1px 2px 2px grey
}
.wwa-desc{
    font-size: 18px;
    width: 400px;
    /* background-color: white; */
}
.wwa-chunks{
    height: 18vh;
    width: 100px;
    border-radius: 50%;
}
.wwa-chunks-div{
    background-color:var(--primary-color);
    /* padding: 20px; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
}
.chunk-names{
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    padding: 4px;
    border-radius: 15px;
}
.india-map-div{
    /* background: url('../../images/Margom-website-edits.png') no-repeat center; */
    position: relative;
    /* top: -285px; */
    z-index: -1 !important;
    height: 50vh;
    display: flex;
    align-items: end;
    justify-content: center;
}
.india-map-img{
    width: inherit;
    /* height: 50vh; */
}
.chunks-container-1,.chunks-container-3{
    position: relative;
    top:  -118px;
}
.chunks-container-2{
    position: relative;
    top:  -50px;
}
@media (max-width: 1024px){
    .wwa-desc{
        font-size: 18px !important;
    }
    .india-map-div{
        width: auto;
    }
}
@media (max-width: 1440px){
    .wwa-desc{
        font-size: 23px;
    }
}
@media (max-width: 468px){
    .wwa-desc{
        font-size: 11px !important;
    }
    .india-map-img{
        height: 30vh;

    }
    .wwa-chunks-div{
        padding: 0px !important;
    }
}
@media (max-width: 768px){
    .wwa-chunks {
        height: 10vh;
        width: 69px;
       
    }
    .wwa-chunks-div{
        padding: 13px;
    }
    .chunk-names{
        font-size: 12px;
    }
    .content-div{
        height: 28vh;
    }
    .wwa-desc{
        font-size: 16px ;
    }
    .wwa-heading{
        font-size: 35px;
    }
    .india-map-div{
        height: 30vh;

    }
    /* .india-map-div{ */
        /* top: -210px; */
    /* } */
}
@media (max-width: 768px){
    .chunks-container-1 ,.chunks-container-3{
        top: -50px !important;
    }
    .chunks-container-2{
        top: -10px !important;
    }
}
@media (max-width: 1042px){
    .chunks-container-1 ,.chunks-container-3{
        top: -100px ;
    }
    .chunks-container-2{
        top: -40px ;
    }
}

.card-container{
    background-color: var(--primary-color);
}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
    display: flex;
    align-items: center;
  }

  .flip-card-inner {
    width: 100%;
    height: 70%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    display: flex;
    align-items: center;

  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    width:100%;
    height: 70%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px;
  }

  .flip-card-front {
    background-color:var(--secondary-color);
  }

  .flip-card-back {
    background-color: var(--secondary-color);
    transform: rotateY(180deg);
  }

  .image {
    max-width: 70%;
    max-height: 70%;
  }

  .name {
    font-size: 22px;
    font-weight: bold;
    color: var(--primary-color);
  }