.landing-heading{
    font-size: 30px;
    color: var(--primary-color) ;
    width: 300px;
    text-shadow: 0px 4px 5px grey;
}
/* .{
    background: url('../../images/landing-image.jpg') no-repeat;
    background-size: cover;
} */
.landing-page{
    background-color: var(--secondary-color);
}
.landingImage{
    border-radius: 151px 0px 0px 0px;
    width: 100%;
    filter: brightness(0.9);
    height: 40vh;
}
.landing-heading-div{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
#demo-simple-select1,#demo-simple-select2{
    background-color:#14777e ;
}
.caraousel{
height: 200px;
background-color:var(--primary-color);
color: whitesmoke;
align-items: center;
}
.bg-select{
    background: url('../../images/landing-page-buddha.png')  no-repeat center center;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    height: 38vh;
    align-items: center;
    background-position:right 20% top 0px, top left;
}

.tagline{
    width: 500px;
    display: flex;
    justify-content: center;
}
.carousel-indicators [data-bs-target]{
    width: 15px !important;
} 
@media screen and (max-width: 1040px) {
    .bg-select {
        height: 28vh;
    }
  }

@media (max-width: 480px){
    .landing-heading{  
     font-size: 15px;
     width: 20vh;
    }
    .landingImage{
        border-radius: 50px 0px 0px 0px;
        height: auto;
    }
    .btn{
        font-size: 10px;
    }
    .bg-select{
        display: flex;
        justify-content: space-around;
    }
    select{
        width: 120px !important;
        font-size: 12px !important;
    }
    .slider{
        width: 100px !important;
        font-size: 10px;
        margin-bottom: 0;
    }
.caraousel{
    height: 150px;
}
.tagline{
    width: auto;
}
/* .bg-select{ */
    /* background: url('../../images/city-2.png') no-repeat bottom; */
    /* height: 10vh; */
/* } */
.landing-heading-div{
    margin-left: 12px;
}
.medimine,.paramount{
    width: 55px !important;
    height: 55px !important;
}
}
.carousel-control-next-icon, .carousel-control-prev-icon{
    height: 1rem !important;
}
.select-design{
    background-color: #14777e;
    color: white;
    font-style: italic;
}


.fade-in-text{
animation: fadeIn 2s;
-webkit-animation: fadeIn 2s;
-moz-animation: fadeIn 2s;
-o-animation: fadeIn 2s;
-ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-o-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}

.animate__fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}


@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.form-select:focus{
    outline: none;
    border-color:#14777e;
    box-shadow: none;
  }
  .patron-img-div{
    display: flex;
    justify-content: center;
    /* width: 50%; */
  }