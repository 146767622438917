.eco-heading{
    color: var(--primary-color);
    font-weight: 600;
    
}
.ecosystem-section{
    background-color: var(--secondary-color);
}

.pivoted-by{
    background: #63918F;
    color: white;
    padding: 95px 127px;
    border-radius: 0px 269px 247px 0px;
}
.exp-by{
    background: var(--primary-color);
    color: white;
    padding: 123px 142px;
    border-radius: 251px 0px 0px 251px;
}