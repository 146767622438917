.header-links{
    text-decoration: none;
    text-align: center;
    padding: 20px 0px;
    color: #6A7A6E;
    font-weight: 600;
    /* width: 138px !important; */
    cursor: pointer;
}
.header-links:hover{
    color: #1e3f25;
}
.header-bg{
    background-color: #DBEFE1;
}
.p-header{
    width: max-content;
    font-size: 17px;
    margin-bottom: 0px !important;
}
.get-in-touch{
    background-color: #1B6166 !important;
    color: white;
}
.get-in-touch-div{
    display: flex;
    /* width: 92%; */
}
.cool-link {
    display: inline-block;
    /* color: #000; */
    text-decoration: none;
}

.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    border-radius: 30px;
    background:  #1B6166 !important;
    transition: width .3s;
}

.cool-link:hover::after {
    width: 100%;
   
}

.desk-header{
    display: flex;
}
#basic-nav-dropdown{
    background-color: #1B6166;
    padding: 7px;
    border-radius: 5px;
    color: #DBEFE1;
}
.mobile-header{
    display: none;
}
.logo-div{
    display: flex;
    justify-content: flex-end;
    margin-left: 2%;
    align-self: center;

}
.logo{
    height: auto;
    
}
@media (max-width: 770px){
    .desk-header{
        display: none !important;
    }
    .mobile-header{
        display: flex;
       
    }
    .logo{
        width: 150px;
    }
    .header-main-div{
        width: 100% !important;
    }
    .get-in-touch-div{
        display: none !important;
    }
    .add-padding{
        padding: 10px 5px;
    }
}
.nav-item{
    display: flex !important;
    align-items: center;
}
.header-main-div{
    width: 80%;
}
.dropdown-toggle::after{
    display: none !important;
}

@media (max-width :480px){
    .logo{
        width: 100px;
    }
  
}
@media (max-width :1024px){
    .p-header{
        font-size: 15px;
    }
  
}
.dropdown .dropdown-menu {
    animation-name: example;
    animation-duration: 1s;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
    opacity: 1;
    transform: scale(1);
  }
  
  .dropdown .dropdown-menu{  
    transform: scale(0);     
    opacity: 0; display:
    block !important;
   }
  .dropdown-menu.show {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
  
  @keyframes example {
    from {
      transform: scale(0);
      opacity: 0;
      -webkit-transition: all .2s linear;
      -o-transition: all .2s linear;
      transition: all .2s linear;
    }
    to {
     -webkit-transition: all .2s linear;
      -o-transition: all .2s linear;
      transition: all .2s linear;
      opacity: 1;
      transform: scale(1);
    }  
}
@media (max-width :1024px){
    .logo-div{
        margin-left: 3%;
    }
}
.dropdown-item:active {
    background-color: var(--secondary-color) !important;
    color: black !important;
}