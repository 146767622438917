.bg-bm-1{
    background-color:var(--secondary-color);
}

.bg-bm-2,.bg-contact{
    background-color:var(--primary-color);
    padding: 30px 0px;
}
.build-model-text{
    color:var(--secondary-color);
}
.bm-bottom-img{
    width: -webkit-fill-available;
}
/* .bg-bottom-city-img{ */
    /* background: url('../../images/who-are-we.png') no-repeat bottom; */
   /* height: 28vh; */
/* } */
@media (max-width: 768px){
.bm-img{
  width: 100%;
}
.bg-bm-2{
    padding:25px;
}
.bg-bottom-city-img{
    /* background: url('../../images/who-are-we.png') no-repeat bottom; */
    height: 18vh;
}
.bm-bottom-img{
    height: 17vh;
}
}
.bm-desc{
    font-size: 18px;
}
@media (max-width :480px){
    .bm-desc{
        font-size: 15px;
    }
    .extraSpace{
        height: 5vh !important;
    }
    .bg-contact{
        padding:0px;
    }
    .form-control{
        width: 80% !important;

    }
}
.form-control{
    width: 80%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid white;
    border-radius: 0px;
    color: white;
    padding: 0px 10px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.form-control::placeholder{
    color: #dbefe1;
}
.form-control:focus {
    color: white;
    background-color:transparent;
    border:none;
    border-bottom: 2px solid var(--secondary-color);
    outline: none;
    box-shadow:none;
}
:focus-visible {
    outline:none;
}
.btn-submit-contact{
    width: 80%;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}
.btn-submit-contact:hover,.btn-submit-contact:focus-visible,.btn-submit-contact:active{
    background-color:var(--secondary-color);
    color: var(--primary-color);
}
.btn-submit-contact:not(.btn-check)+.btn:active{
    background-color: var(--secondary-color)!important;
    color: var(--primary-color) !important;
}
.btn-submit-contact:checked+.btn-submit-contact, .btn-submit-contact.active, .btn-submit-contact.show, .btn-submit-contact:first-child:active, :not(.btn-submit-contact)+.btn:active{
    background-color: #98f3c9 !important;
    color: var(--primary-color) !important;
}
.error{
    display: flex;
    text-align: right;
    justify-content: end;
    width: 80%;
}