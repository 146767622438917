.hwe-mid-div{
    background-color:var(--secondary-color);
    border-radius: 35px;
}
.water-drop img{
    width: 125px;
    border-radius: 50%;
    height: 14vh;
    opacity: 0.75;
}
.water-div{
    background: black;
    border-radius: 64%;
    height: 17vh;
    width: max-content;
    padding: 5px;
}
.triangle-div{
    background: black;
    padding: 30px;
}

.water-drop{
    width: 190px;
    height: 190px;
    background-color:var(--primary-color);
    border-radius: 50%;
    border-top-right-radius: 0;
    transform: rotate(-45deg);
    float: left;
    margin-top: 50px;
    margin-top: -35px;
    margin-left: 20px;
    align-self: center;
    /* border: 5px solid #fff; */
}
.inside-drop-img{
    transform: rotate(45deg);
}
.align-drop-div{
    display: flex;
    width: 100%;
    align-items: baseline;
    /* justify-content: space-around; */
}
.c-round{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color:var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -10px;
    z-index: 10;
    color: white;
}
.c-div{
    display: flex;
    justify-content: center;
    padding: 13px;
    /* margin-left: -16%; */
}
.c-name{
    width: 150px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#a2c3ac;
    padding: 7px;
    border-radius: 5px;
    z-index: inherit;
    color:#1B6166;
}
.c-4-div{
/* margin: 20px 0px; */
margin-top: -37px;
}
.bg-sec-div{
    background-color:var(--primary-color);
    color: white;    
    padding: 120px 20px 20px 20px;
    position: relative;
    z-index: 0;
    margin-top: -42px;
}
.F-div{
    margin: 200px 0px 0px 0px;
    position: relative;
    z-index: 10;
}
.align-c-drop{
    display: flex;
    flex-direction: column;
}
.water-drop-img-desktop{
    margin-top:-150px
}
@media (max-width: 768px){
    /* .align-c-drop{
        padding: 0px 25px 80px 25px;
    } */
    .water-drop-img-desktop{
        margin-top:-81px
    }
    .c-div{
        margin-left: 0px;
    }
    .F-div{
        margin: 100px 0px 0px 0px;
    }
    .hwe-bottomImg{
        margin-top: 0px !important;
    }
   
 
}
@media (max-width :1440px){
  .water-drop img{
    height: 20vh;
  }
}
/* Website edits (1440 × 1024px) (8) 1 */

.hwe-bottomImg{
    margin-top: -26px;
    width: 101% ;
}
.bg-model{
    background-color: var(--secondary-color);
}
.water-drop-show-hide-mobile{
    display: none;
}
.water-drop-show-hide-desk{
    display: block;
}
@media (max-width :480px){
    .water-drop-show-hide-mobile{
        display: block !important;
   }
   .water-drop-show-hide-desk{
    display: none !important;
}
.water-drop img{
    height: 16vh
  }
}
.bg-color-wd-2{
    background-color: var(--secondary-color);
}